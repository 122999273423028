import React from 'react'
import { inject, observer } from 'mobx-react'
import Breadcrumb from '@hkpca-web/components/navigations/breadcrumb'
import { t, toJS } from '@src/utils/commonUtil'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect"
import queryString from 'query-string'
import LatestPostList from '@hkpca-web/components/templates/LatestPostList'
import Collapse from '@material-ui/core/Collapse'
import { withTranslation } from 'react-i18next'
import MemberSearchField from '@hkpca-web/components/inputs/MemberSearchField'
import MemberCategoryPicker from '@hkpca-web/components/inputs/MemberCategoryPicker'

@withTranslation()
@inject('initDataStore')
@observer

class AboutUs extends React.Component {
  constructor (props) {
    super(props)
    const memberList = props.initDataStore.allContents.informations.member
    this.state = {
      collapseStateList: null,
      searchText: '',
      memberBusinessNature: 'ALL',
      memberCity: 'ALL',
      memberLocation: 'ALL',
      offset: 0,
      limit: 1000,
      list: memberList
    }
  }

  componentDidMount () {
    const { initDataStore } = this.props
    const collapseStateList = Array.from({ length: initDataStore.allContents.informations.member.length }, () => false)
    this.setState({
      collapseStateList: collapseStateList
    })
  }

  toogleMemberDetail = (key) => {
    const { initDataStore } = this.props
    const collapseStateList = this.state.collapseStateList
    collapseStateList[key] = !collapseStateList[key]
    this.setState({
      collapseStateList: collapseStateList
    })
  }

  searchAction = async (value) => {
    const { initDataStore } = this.props
    this.setState(value, async () => {
      const { searchText, memberBusinessNature, memberCity, memberLocation, offset, limit } = this.state
      const data = await initDataStore.searchMemberList(
        searchText,
        memberBusinessNature,
        memberCity,
        memberLocation,
        offset,
        limit
      )
      this.setState({ list: data })
    })
  }

  render () {
    const { i18n, initDataStore } = this.props
    const { list, collapseStateList } = this.state
    const { toogleMemberDetail, searchAction } = this
    const memberBusinessNatureList = initDataStore.allContents.categories.memberBusinessNature
    const memberCityList = initDataStore.allContents.categories.memberCity
    const memberLocationList = initDataStore.allContents.categories.memberLocation
    const membershipTypeList = initDataStore.allContents.categories.membershipType
    // toJS(initDataStore.allContents.categories.memberBusinessNature)
    return (
      <>
        <div className='member-list'>
          <div className='container'>
            <div className='search-bar'>
              <div className='search-input'>
                <MemberSearchField name='searchText' value={this.state.searchText} placeholder={i18n.t('other.searchPlaceholder')} iconName={'search'} onSubmit={searchAction} />
              </div>
              <div className='picker member-business-nature-picker'>
                <div className='title'>{i18n.t('member.businessNature')}</div>
                <MemberCategoryPicker language={i18n.language} name='memberBusinessNature' optionList={memberBusinessNatureList} value={this.state.memberBusinessNature} onSubmit={searchAction} hasNoneOption={false} hasAllOption={true} />
              </div>
              <div className='picker member-city-picker'>
                <div className='title'>{i18n.t('member.city')}</div>
                <MemberCategoryPicker language={i18n.language} name='memberCity' optionList={memberCityList} value={this.state.memberCity} onSubmit={searchAction} hasNoneOption={false} hasAllOption={true} />
              </div>
              <div className='picker member-location-picker'>
                <div className='title'>{i18n.t('member.country')}</div>
                <MemberCategoryPicker language={i18n.language} name='memberLocation' optionList={memberLocationList} value={this.state.memberLocation} onSubmit={searchAction} hasNoneOption={false} hasAllOption={true} />
              </div>
            </div>
            {
              collapseStateList && list.map((member, key) => {
                let city = ''
                if (member.city) {
                  city = memberCityList.find((city) => city.value === member.city).label[i18n.language]
                }
                let country = ''
                if (member.country) {
                  country = memberLocationList.find((country) => country.value === member.country).label[i18n.language]
                }
                let businessNature = ''
                if (member.businessNature) {
                  businessNature = memberBusinessNatureList.find((businessNature) => businessNature.value === member.businessNature).label[i18n.language]
                }
                let membershipType = ''
                if (member.membershipType) {
                  membershipType = membershipTypeList.find((type) => type.value === member.membershipType).label[i18n.language]
                }
                const companyNameEn = member.companyName.en
                let companyNameCh = member.companyName.tc

                if (i18n.language === 'sc') {
                  companyNameCh = member.companyName.sc
                }

                return (
                  <div className='member' key={key} onClick={toogleMemberDetail.bind(this, key)}>
                    {
                      collapseStateList[key]
                        ?
                        <div className='flex-row space-between'>
                          <div className='member-code active'>
                            <div>{companyNameEn}</div>
                            <div>{companyNameCh}</div>
                          </div>
                          <div className='member-company-name active'>{businessNature}</div>
                        </div>
                        :
                        <></>
                    }
                    {/* <hr /> */}
                    <div className='flex-row space-between'>
                      {
                        collapseStateList[key]
                          ?
                          <>
                            <div className='member-code active'>
                              {/* <div>{companyNameEn}</div>
                              <div>{companyNameCh}</div> */}
                            </div>
                          </>
                          :
                          <div className='member-code'>
                            <div>{companyNameEn}</div>
                            <div>{companyNameCh}</div>
                          </div>
                      }
                      {/* {
                        collapseStateList[key]
                          ?
                          <div className='member-code active'>{businessNature}</div>
                          :
                          <div className='member-code'>{businessNature}</div>
                      } */}
                      {
                        collapseStateList[key]
                          ?
                          <div className='member-content'>
                            <Collapse in={collapseStateList[key]} >
                              <div className='content flex-column'>
                                {/* <div className='flex-row space-between'>
                                  <div className='business-nature active'>{businessNature}</div>
                                </div> */}
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.membershipType')}</div>
                                  <div className='text'>{membershipType && membershipType}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.memberCode')}</div>
                                  <div className='text'>{member.memberCode}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.name')}</div>
                                  <div className='text'>{member.name[i18n.language]}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.address')}</div>
                                  <div className='text'>{member.address[i18n.language]}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.city')}</div>
                                  <div className='text'>{city}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.country')}</div>
                                  <div className='text'>{country}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.emailAddress')}</div>
                                  <div className='text'>{member.email}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.phone')}</div>
                                  <div className='text'>{member.phone}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.fax')}</div>
                                  <div className='text'>{member.fax}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.website')}</div>
                                  <div className='text'>{member.website}</div>
                                </div>
                                <div className='flex-row space-between'>
                                  <div className='title'>{i18n.t('member.companyIntroduction')}</div>
                                  <div className='text'>{member.companyIntroduction[i18n.language]}</div>
                                </div>
                              </div>
                            </Collapse>
                          </div>
                          :
                          <div className='member-company-name'>{businessNature}</div>
                      }
                    </div>
                    <hr />
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }
}
export default AboutUs
