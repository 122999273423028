import React, { useState, useRef } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { Document, Page } from 'react-pdf/dist/entry.webpack';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SendIcon from '@material-ui/icons/Send';


function Wowbook(props) {
  console.log(props)
    var url = "/vendor/index.html?pdf=" + props.file + "&desc=" + props.desc; 
    if(props.download[0]) {
      url += "&download=" + props.download[0].original
    }
    return (
    <>
		<iframe style={{width:'100vw',height:'100vh',position:'fixed',top:0,left:0,zIndex:999}} src={url}></iframe>
    </>
    );
}
export default Wowbook;
